


















































































































import { Component, Prop } from 'vue-property-decorator';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import VueContextLoading from '@/components/VueContextLoading';
import FileResource from '@/models/graphql/FileResource';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import PillWidget from '@/components/pill/PillWidget.vue';
import { formatInTimeZone } from 'date-fns-tz';
import { Getter, namespace, State } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import Community from '@/models/graphql/Community';
import { mixins } from 'vue-class-component';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const communityUserBookmarkStore = namespace('CommunityUserBookmarkStore');

@Component({
  components: {
    FontAwesomeComponent,
    ButtonIconComponent,
    PillWidget,
  },
})
/* eslint-disable no-underscore-dangle */
export default class ArticleCardWidget extends mixins(VueContextLoading, BaseCardWidget) {
  @Getter
  community!: Community;

  @Prop({ required: true })
  readonly uid!: string;

  @Prop({ required: true, default: -1 })
  readonly id!: number;

  @Prop({ required: true })
  readonly name!: string;

  @Prop({ required: false, default: '' })
  readonly content!: string;

  @Prop({ required: false })
  readonly images!: FileResource[];

  @Prop({ required: true, default: '' })
  readonly publicationTime!: string;

  @Prop({ required: true })
  readonly _isRecommendedForMe!: boolean;

  @Prop({ required: true })
  readonly featured!: boolean;

  @Prop({ required: false, default: null })
  readonly _isBookmarked!: string | null;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ default: 'DependencyWidgetStore' })
  readonly context!: string;

  @Prop({ required: false, default: true })
  readonly displayRecommendedBadge!: boolean;

  @Prop({ required: false, default: true })
  readonly displayFeaturedBorder!: boolean;

  @communityUserBookmarkStore.Action
  bookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserBookmarkStore.Action
  unBookmark!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @State
  private dateLocale!: Locale;

  @Getter
  private authUser!: CommunityUser;

  private isHovered = false;

  private FileResourceHelper = FileResourceHelper;

  private bookmarked = this._isBookmarked;

  private get formattedDate(): string {
    if (this.publicationTime) {
      const startTime = DateTimeHelper
        .toLocal(new Date((this.publicationTime as unknown) as string));
      return `${formatInTimeZone(startTime, DateTimeHelper.currentTimeZone(), 'p - PP', { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.uid) {
            r = r.replaceAll(m, this.uid);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private toggleBookmark(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.bookmarked) {
      const temp = this.bookmarked;
      this.bookmarked = null;
      this.unBookmark({ uid: temp })
        .catch(() => {
          this.bookmarked = temp;
        });
    } else {
      this.bookmarked = 'bookmarked';
      this.bookmark({
        userId: this.authUser.uid,
        linkedUserId: this.uid,
        entityType: EntityType.ARTICLE,
      }).then((response) => {
        this.bookmarked = response?.uid || '';
        if (this.bookmarked) {
          this.$logger.logMatomoStats(
            this.authUser,
            this.community.code as string,
            EntityType.ARTICLE,
            StatLoggerActions.ADD,
            '',
            this.id,
            this.uid,
            StatLoggerCategories.BOOKMARK,
            this.$i18n.locale,
          );
        }
      }).catch(() => {
        this.bookmarked = null;
      });
    }
  }
}
